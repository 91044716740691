import { FC, forwardRef } from 'react';
import styled, { css, CSSObject } from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Icon, IconProps } from '../icon';
import { Loader } from '../loader';

type ButtonType = JSX.IntrinsicElements['button'];
type AnchorType = JSX.IntrinsicElements['a'];

export interface ButtonProps {
  variant?:
    | 'primary'
    | 'secondary'
    | 'outline'
    | 'outlineInverted'
    | 'tertiary'
    | 'tertiaryOutlined';
  size?: 'large' | 'medium' | 'small' | 'xSmall';
  isLoading?: boolean;
  className?: string;
  disabled?: boolean;
  iconLeft?: IconProps['component'];
  iconRight?: IconProps['component'];
  ref?: any;
  form?: string;
  fontSize?: keyof typeof globalTheme.fontSizes;
}

function isPropsForAnchorElement(
  props: ButtonType | AnchorType
): props is AnchorType {
  return 'href' in props;
}

const StyledLoader = styled(Loader)`
  /* Styled loader */
`;

const getSizeStyles = (props: ButtonProps): CSSObject => {
  let paddingSize = globalTheme.space[1];

  // xSmall
  let sizeVars: CSSObject = {
    fontSize: globalTheme.fontSizes[0],
    minWidth: '80px',
    height: globalTheme.space[3],
    get padding() {
      return `0 ${paddingSize}`;
    },
    get borderRadius() {
      return `calc(${this.height} / 2)`;
    },
    get gap() {
      return `calc(${paddingSize} / 2)`;
    },
  };

  // Small
  if (props.size === 'small') {
    paddingSize = globalTheme.space[2];
    sizeVars.fontSize = globalTheme.fontSizes[1];
    sizeVars.height = globalTheme.space[4];
  }

  // Medium
  if (props.size === 'medium') {
    paddingSize = globalTheme.space[3];
    sizeVars.fontSize = globalTheme.fontSizes[3];
    sizeVars.minWidth = '144px';
    sizeVars.height = globalTheme.space[5];
  }

  // Large
  if (props.size === 'large') {
    paddingSize = globalTheme.space[3];
    sizeVars.fontSize = globalTheme.fontSizes[3];
    sizeVars.minWidth = '200px';
    sizeVars.height = globalTheme.space[7];
  }

  return sizeVars;
};

const getVariantStyles = (props: ButtonProps) => {
  // Primary
  let variantVars = {
    default: {
      backgroundColor: globalTheme.elements.button.primary.backgroundColor,
      color: globalTheme.elements.button.primary.color,
      innerBorderColor: 'transparent',
    },
    focus: {
      borderColor: globalTheme.elements.button.primary.focus.borderColor,
      innerBorderColor: 'transparent',
    },
  };

  // Secondary
  if (props.variant === 'secondary') {
    variantVars.default.color = globalTheme.elements.button.secondary.color;
    variantVars.default.backgroundColor =
      globalTheme.elements.button.secondary.backgroundColor;
    variantVars.focus.borderColor =
      globalTheme.elements.button.secondary.borderColor;
  }

  // Outline
  if (props.variant === 'outline') {
    variantVars.default.color = globalTheme.elements.button.outline.color;
    variantVars.default.backgroundColor =
      globalTheme.elements.button.outline.backgroundColor;
    variantVars.default.innerBorderColor =
      globalTheme.elements.button.outline.innerBorderColor;
    variantVars.focus.borderColor =
      globalTheme.elements.button.outline.focus.borderColor;
    variantVars.focus.innerBorderColor =
      globalTheme.elements.button.outline.focus.innerBorderColor;
  }

  // Outline Inverted
  if (props.variant === 'outlineInverted') {
    variantVars.default.color =
      globalTheme.elements.button.outlineInverted.color;
    variantVars.default.backgroundColor = 'transparent';
    variantVars.default.innerBorderColor =
      globalTheme.elements.button.outlineInverted.innerBorderColor;
    variantVars.focus.borderColor = 'transparent';
    variantVars.focus.innerBorderColor =
      globalTheme.elements.button.outlineInverted.focus.innerBorderColor;
  }

  // Tertiary
  if (props.variant === 'tertiary') {
    variantVars.default.color = globalTheme.elements.button.tertiary.color;
    variantVars.default.backgroundColor =
      globalTheme.elements.button.tertiary.backgroundColor;
    variantVars.focus.borderColor =
      globalTheme.elements.button.tertiary.borderColor;
  }

  // Tertiary Outlined
  if (props.variant === 'tertiaryOutlined') {
    variantVars.default.color =
      globalTheme.elements.button.tertiaryOutlined.color;
    variantVars.default.backgroundColor =
      globalTheme.elements.button.tertiaryOutlined.backgroundColor;
    variantVars.default.innerBorderColor =
      globalTheme.elements.button.tertiaryOutlined.innerBorderColor;
    variantVars.focus.borderColor =
      globalTheme.elements.button.tertiaryOutlined.focus.borderColor;
    variantVars.focus.innerBorderColor =
      globalTheme.elements.button.tertiaryOutlined.focus.innerBorderColor;
  }

  const stateStyles = css`
    &:hover,
    &.hover,
    &:disabled,
    &.disabled {
      color: ${globalTheme.elements.button.disabledHoverColor};
      background-color: ${globalTheme.elements.button
        .disabledHoverBackgroundColor};
      box-shadow: none;
    }

    &:focus,
    &.focus {
      box-shadow: inset 0 0 0 2px ${variantVars.focus.innerBorderColor},
        0 0 0 2px ${globalTheme.colors.white},
        0 0 0 4px ${variantVars.focus.borderColor};
    }

    &:disabled,
    &.disabled {
      cursor: not-allowed;
      opacity: 0.25;
    }
  `;

  return css`
    color: ${variantVars.default.color};
    background-color: ${variantVars.default.backgroundColor};
    box-shadow: inset 0 0 0 2px ${variantVars.default.innerBorderColor};

    ${StyledLoader} {
      * {
        fill: ${variantVars.default.color};
      }
    }

    /* Include state styles when not in loading state */
    ${!props.isLoading && stateStyles}

    &:focus:not(:focus-visible) {
      box-shadow: inset 0 0 0 2px ${variantVars.default.innerBorderColor};
    }
  `;
};

export const ButtonStyled = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: ${globalTheme.transitions.fast};

  /* Size variants */
  ${getSizeStyles}

  /* Theme variants */
  ${getVariantStyles}
  
  font-size: ${({ fontSize }) => globalTheme.fontSizes[fontSize || 'default']};
`;

const IconLeft = styled(Icon)`
  margin-inline-end: auto;
  color: currentColor;
`;

const IconRight = styled(Icon)`
  margin-inline-start: auto;
  color: currentColor;
`;

const ButtonOrAnchor: FC<ButtonProps> = forwardRef<any, ButtonProps>(
  (props, ref) => {
    if (isPropsForAnchorElement(props)) {
      return <ButtonStyled {...props} as='a' ref={ref} />;
    } else {
      return (
        <ButtonStyled
          {...props}
          disabled={props.isLoading || props.disabled}
          ref={ref}
        />
      );
    }
  }
);

const ButtonBase: FC<ButtonProps & (ButtonType | AnchorType)> = forwardRef<
  ButtonType | AnchorType,
  ButtonProps
>(
  (
    {
      variant = 'primary',
      size = 'large',
      iconLeft,
      iconRight,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <ButtonOrAnchor {...props} variant={variant} size={size} ref={ref}>
        {props.isLoading ? (
          <StyledLoader size={size === 'small' || size === 'xSmall' ? 1 : 2} />
        ) : (
          <>
            {iconLeft && <IconLeft component={iconLeft} />}
            {children}
            {iconRight && <IconRight component={iconRight} />}
          </>
        )}
      </ButtonOrAnchor>
    );
  }
);

export const Button = styled(ButtonBase)`
  /* Button */
`;

export const UnStyledButton = styled.button`
  padding: 0;
  color: inherit;
  font: inherit;
  background: none;
  border: none;
  outline: inherit;
  cursor: pointer;
`;
