import { useNanoID } from 'hooks/use-nanoid';
import {
  FC,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  useState,
} from 'react';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Icon, IconProps } from '../icon';
import { AnimatePresence } from 'framer-motion';
import { InputMessage } from '../input-message';
import { getStateValues, InputProps } from '../input';

export interface RadioBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  id?: string;
  icon?: IconProps['component'];
  divRef?: ForwardedRef<HTMLDivElement>;
  ref?: ForwardedRef<HTMLInputElement>;
  checkbox?: boolean;
  orgFontSize?: any;
  isMobile?: any;
  height?: number | string;
  disabled?: boolean;
  variant?: 'compact' | 'default';
  iconSize?: keyof typeof globalTheme.space | string;
  message?: string;
  state?: InputProps['state'];
  bookingCell?: boolean;
}

const Wrapper = styled.div`
  display: inline-flex;
`;

const Input = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
`;

export const RadioBoxLabel = styled.label<{
  height?: number | string;
  orgFontSize: any;
  isMobile: any;
  variant?: 'compact' | 'default';
  $bookingCell?: boolean;
}>`
  display: flex;
  width: 100%;
  height: ${(props) =>
    props.variant === 'compact' ? '' : props.height ? props.height : '56px'};
  padding: ${(props) =>
    props.variant === 'compact'
      ? `4px ${globalTheme.space[1]}`
      : `0 ${globalTheme.space[1]}`};
  color: ${globalTheme.elements.radioBox.textColor};
  font-size: ${(props) =>
    props?.orgFontSize && props.isMobile
      ? globalTheme.fontSizes[4]
      : props?.$bookingCell
      ? globalTheme.fontSizes.bookingMobile
      : globalTheme.fontSizes[1]};
  background-color: ${globalTheme.elements.radioBox.backgroundColor};
  border: 1px solid ${globalTheme.elements.radioBox.borderColor};
  border-radius: 20px;
  transition: ${globalTheme.transitions.fast};
  place-items: center;
  place-content: center;

  ${Icon} {
    margin-inline-end: ${globalTheme.space[0]};
  }

  ${Input}:checked + & {
    border-color: ${globalTheme.elements.radioBox.checkedBorderColor};
    box-shadow: 0 0 0 1px ${globalTheme.colors.blue};
    color: ${globalTheme.colors.white};
    background-color: ${globalTheme.elements.radioBox.checkedBorderColor};
  }

  ${Input}:focus + & {
    box-shadow: 0 0 0 2px ${globalTheme.colors.white},
      0 0 0 4px ${globalTheme.colors.blue};
  }

  ${Input}:focus:not(:focus-visible) + & {
    box-shadow: 0 0 0 1px ${globalTheme.colors.blue};
  }

  ${Input}:disabled + & {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const RadioBox: FC<RadioBoxProps> = forwardRef<
  HTMLInputElement,
  RadioBoxProps
>(
  (
    {
      id: passedId,
      label,
      icon,
      divRef,
      className,
      children,
      checkbox,
      height,
      disabled,
      orgFontSize,
      isMobile,
      variant = 'default',
      iconSize = 2,
      message,
      state,
      bookingCell,
      ...inputProps
    },
    ref
  ) => {
    const fallbackId = useNanoID();
    const id = passedId || `id${fallbackId}`;
    const { stateColor } = getStateValues(state);
    // Popper reference element
    const [referenceElement, setReferenceElement] =
      useState<HTMLDivElement | null>(null);

    return (
      <div ref={setReferenceElement}>
        <Wrapper className={className} ref={divRef}>
          <Input
            id={id}
            type={checkbox ? 'checkbox' : 'radio'}
            ref={ref}
            {...inputProps}
            disabled={disabled}
          />
          <RadioBoxLabel
            htmlFor={id}
            height={height}
            orgFontSize={orgFontSize}
            isMobile={isMobile}
            variant={variant}
            $bookingCell={bookingCell}
          >
            {icon && <Icon component={icon} size={iconSize} />}
            {children} {label}
          </RadioBoxLabel>
          <AnimatePresence>
            {message && (
              <InputMessage
                backgroundColor={stateColor}
                referenceElement={referenceElement}
              >
                {message}
              </InputMessage>
            )}
          </AnimatePresence>
        </Wrapper>
      </div>
    );
  }
);
