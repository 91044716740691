import { DialogContent, DialogOverlay } from '@reach/dialog';
import '@reach/dialog/styles.css';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { useNanoID } from 'hooks/use-nanoid';
import { rgba } from 'polished';
import { FC, ReactChild, useEffect, useState } from 'react';
import styled from 'styled-components';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';
import { Heading } from '../heading';
import { Icon } from '../icon';
import { VisuallyHidden } from '../visually-hidden';

export interface DialogProps {
  title: string;
  dataCypress?: string;
  actions?: ReactChild;
  isOpen?: boolean;
  largeDialog?: boolean;
  onDismiss?: () => void;
  onBackClick?: VoidFunction;
  smallDialog?: boolean;
  overlayBackgroundColour?: string;
  hasBlurredOverlay?: boolean;
  showCancelIcon?: boolean;
  contentRef?: React.RefObject<HTMLDivElement>;
  isBookingModal?: boolean;
  dismissOnOverlayClick?: boolean;
  $smallDialogOnMobile?: boolean;
  mobileInputActive?: boolean;
}

const PADDING_XSMALL = globalTheme.space[1];
const PADDING_SMALL = globalTheme.space[2];
const PADDING_LARGE = globalTheme.space[3];
const PADDING_X_LARGE = globalTheme.space[7];

const overlay: Variants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
    transition: {
      delay: 0.25,
    },
  },
};

const content: Variants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.25,
    },
  },
  hidden: {
    opacity: 0,
    y: 32,
  },
};

const Overlay = styled(motion(DialogOverlay))<{
  $backgroundColour?: string;
  $blur?: boolean;
}>`
  z-index: ${globalTheme.zIndices.dialog};
  display: flex;
  background-color: ${(props) =>
    props.$backgroundColour || rgba(globalTheme.colors.darkBlue, 0.9)};
  backdrop-filter: ${(props) => (props.$blur ? 'blur(8px)' : 'none')};
  justify-content: center;
  align-items: center;
`;

const StyledHeading = styled(Heading)`
  grid-column: 2 / span 1;
`;

const Content = styled(
  motion<{
    children?: any;
    $isSmall?: boolean;
    $isBookingModal?: boolean;
    $smallDialogOnMobile?: boolean;
  }>(DialogContent)
)`
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  width: 100%;
  max-width: ${(props) =>
    props.$isSmall ? `min(85vw, 320px)` : `min(85vw, 640px)`};
  max-height: ${(props) => (props.$isBookingModal ? `95vh` : `85vh`)};
  margin: auto;
  padding: 0;
  overflow: hidden;
  background-color: ${globalTheme.elements.dialog.backgroundColor};
  border-radius: 32px;

  &[data-large='true'] {
    max-width: min(85vw, 1024px);
  }

  @media screen and (max-width: ${breakpoints.small}) {
    min-width: ${(props) => (props.$smallDialogOnMobile ? `unset` : `100vw`)};
    min-height: ${(props) => (props.$smallDialogOnMobile ? `unset` : `100vh`)};
    border-radius: ${(props) => !props.$smallDialogOnMobile && `0px`};
  }
`;

const Header = styled.div<{ isSmall?: boolean; isBookingModal?: boolean }>`
  display: grid;
  grid-template-columns: ${globalTheme.space[1]} 1fr ${globalTheme.space[1]};
  gap: ${globalTheme.space[1]};
  padding: ${(props) =>
    props.isBookingModal && !props.isSmall
      ? `${globalTheme.space[0]}`
      : props.isSmall
      ? PADDING_XSMALL
      : PADDING_SMALL};
  text-align: center;
  border-bottom: 1px solid
    ${globalTheme.elements.dialog.headerBorderBottomColor};

  * {
    margin: 0;
  }

  @media screen and (min-width: ${breakpoints.large}) {
    padding: ${(props) =>
      props.isBookingModal && !props.isSmall
        ? `${globalTheme.space[0]}`
        : props.isSmall
        ? PADDING_XSMALL
        : PADDING_LARGE};
  }
`;

const CloseButton = styled.button`
  position: relative;
  padding: 0;
  background-color: transparent;
  border: 0;
  outline: 0;

  /* Tap target */
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    border: 2px solid transparent;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    content: '';
  }

  &:focus::before {
    border-color: ${globalTheme.elements.dialog.closeButtonBorderColor};
  }

  &:focus:not(:focus-visible)::before {
    border-color: transparent;
  }
`;

const Body = styled.div<{
  isSmall?: boolean;
  isBookingModal?: boolean;
  $smallDialogOnMobile?: boolean;
}>`
  /* Internal padding */
  --dialog-body-padding: ${PADDING_SMALL};

  height: 100%;
  padding: ${(props) =>
    !props.isSmall && props.isBookingModal
      ? `2px var(--dialog-body-padding)`
      : `var(--dialog-body-padding)`};

  overflow: auto;
  position: relative;

  @media screen and (min-width: ${breakpoints.large}) {
    --dialog-body-padding: ${(props) =>
      props.isSmall ? PADDING_SMALL : PADDING_LARGE};
  }
  @media screen and (max-width: ${breakpoints.small}) {
    padding-bottom: ${(props) => !props.$smallDialogOnMobile && '200px'};
  }

  ${(props) =>
    !props.isBookingModal &&
    `
    @media screen and (min-width: ${breakpoints.large}) {
      --dialog-body-padding: ${props.isSmall ? PADDING_SMALL : PADDING_LARGE};
    }

    @media screen and (min-width: ${breakpoints.xLarge}) {
      --dialog-body-padding: ${props.isSmall ? PADDING_SMALL : PADDING_X_LARGE};

      padding: ${
        props.isSmall
          ? `${PADDING_XSMALL} ${PADDING_SMALL}`
          : `${PADDING_LARGE} ${PADDING_X_LARGE}`
      };
    }
  `}
`;

export const DialogBodyBreakout = styled.div`
  width: calc(100% + var(--dialog-body-padding) * 2);
  transform: translateX(calc(var(--dialog-body-padding) * -1));
`;

export const DialogImageWrapper = styled.div`
  width: 120px;
  height: 120px;
  margin: 0 auto;
`;

export const AnimatedDialogImageWrapper = motion(DialogImageWrapper);
AnimatedDialogImageWrapper.defaultProps = {
  animate: { scale: 1 },
  initial: { scale: 0 },
};

const Actions = styled.div<{
  showScrollShadow?: boolean;
  isBookingModal?: boolean;
  isSmall?: boolean;
  isInputActive?: boolean;
}>`
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  gap: ${globalTheme.space[0]};
  padding: ${(props) =>
    props.isBookingModal && !props.isSmall
      ? `${globalTheme.space[0]}`
      : PADDING_SMALL};
  place-content: center;

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-shadow: 0 -6px 8px rgba(0, 0, 0, 0.05);
    opacity: ${(props) => (props.showScrollShadow ? 1 : 0)};
    content: '';
  }

  @media screen and (min-width: ${breakpoints.large}) {
    /* padding: ${PADDING_LARGE}; */
    padding: ${(props) =>
      props.isBookingModal ? `${globalTheme.space[0]}` : PADDING_LARGE};
  }

  @media screen and (max-width: ${breakpoints.small}) {
    z-index: ${(props) => (props.isInputActive ? '-1' : '0')};
    position: ${(props) => (props.isInputActive ? 'relative' : 'absolute')};
    background-color: white;
    border-top: 1px solid ${globalTheme.colors.lightGray};
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  }
`;

export const Dialog: FC<DialogProps> = ({
  children,
  title,
  dataCypress,
  actions,
  isOpen: isOpenProp,
  largeDialog,
  onDismiss,
  onBackClick,
  smallDialog,
  overlayBackgroundColour,
  hasBlurredOverlay,
  showCancelIcon = true,
  contentRef = null,
  isBookingModal,
  dismissOnOverlayClick = true,
  $smallDialogOnMobile = false,
  mobileInputActive,
}) => {
  const isOpenPropDefined = isOpenProp !== undefined;

  const [isOpen, setIsOpen] = useState(isOpenPropDefined ? isOpenProp : true);

  const labelID = useNanoID();

  const close = () => {
    if (!isOpenPropDefined) {
      setIsOpen(false);
    }

    onDismiss && onDismiss();
  };

  // Controlled mode
  useEffect(() => {
    if (isOpenPropDefined && isOpenProp !== isOpen) {
      setIsOpen(isOpenProp);
    }
  }, [isOpen, isOpenProp, isOpenPropDefined]);

  return (
    <AnimatePresence>
      {isOpen && (
        <Overlay
          key='dialogOverlay'
          onDismiss={dismissOnOverlayClick ? close : undefined}
          initial='hidden'
          exit='hidden'
          animate={'visible'}
          $backgroundColour={overlayBackgroundColour}
          $blur={hasBlurredOverlay !== undefined ? hasBlurredOverlay : true}
          variants={overlay}
        >
          <Content
            data-large={largeDialog}
            aria-labelledby={labelID}
            initial='hidden'
            exit='hidden'
            animate={'visible'}
            variants={content}
            data-cy={dataCypress || 'dialog'}
            $isSmall={smallDialog !== undefined ? smallDialog : false}
            ref={contentRef}
            $isBookingModal={isBookingModal}
            $smallDialogOnMobile={$smallDialogOnMobile}
          >
            <Header isSmall={smallDialog} isBookingModal={isBookingModal}>
              {onBackClick && (
                <CloseButton onClick={onBackClick}>
                  <Icon component='ChevronBackIcon' size={1} />
                  <VisuallyHidden>Go back</VisuallyHidden>
                </CloseButton>
              )}
              <StyledHeading
                level={smallDialog ? 'h5' : 'h3'}
                forwardedAs='h2'
                id={labelID}
              >
                {title}
              </StyledHeading>
              {showCancelIcon && (
                <CloseButton onClick={close} data-cy='closeDialogButton'>
                  <Icon component='CancelIcon' size={1} />
                  <VisuallyHidden>Close dialog</VisuallyHidden>
                </CloseButton>
              )}
            </Header>
            <Body
              isSmall={smallDialog}
              $smallDialogOnMobile={$smallDialogOnMobile}
              isBookingModal={isBookingModal}
            >
              {children}
            </Body>
            {actions && (
              // TODO: Programmatic and performant way to set the scroll shadow when body has overflow
              <Actions
                isSmall={smallDialog}
                showScrollShadow={false}
                isBookingModal={isBookingModal}
                isInputActive={mobileInputActive}
              >
                {actions}
              </Actions>
            )}
          </Content>
        </Overlay>
      )}
    </AnimatePresence>
  );
};

Dialog.defaultProps = {
  isOpen: true,
};
