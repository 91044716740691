/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContactDetailed } from '../model';
// @ts-ignore
import { PaginatedContactDetailedList } from '../model';
// @ts-ignore
import { PrivateGroupAddContact } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * OrganisationPrivateGroupContactApi - axios parameter creator
 * @export
 */
export const OrganisationPrivateGroupContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds the Contact to the Private group
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {PrivateGroupAddContact} privateGroupAddContact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateContactAddCreate: async (buyerOrgId: string, privateGroupId: string, privateGroupAddContact: PrivateGroupAddContact, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPrivateContactAddCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'privateGroupId' is not null or undefined
            assertParamExists('orgPrivateContactAddCreate', 'privateGroupId', privateGroupId)
            // verify required parameter 'privateGroupAddContact' is not null or undefined
            assertParamExists('orgPrivateContactAddCreate', 'privateGroupAddContact', privateGroupAddContact)
            const localVarPath = `/v2/org/{buyer_org_id}/private/{private_group_id}/contact/add`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"private_group_id"}}`, encodeURIComponent(String(privateGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(privateGroupAddContact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Once removed from the Private group, the contact will still exist under the organisation
         * @summary Removes the contact from the Private group
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {string} privateGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateContactDestroy: async (buyerOrgId: string, contactId: string, privateGroupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPrivateContactDestroy', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('orgPrivateContactDestroy', 'contactId', contactId)
            // verify required parameter 'privateGroupId' is not null or undefined
            assertParamExists('orgPrivateContactDestroy', 'privateGroupId', privateGroupId)
            const localVarPath = `/v2/org/{buyer_org_id}/private/{private_group_id}/contact/{contact_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contactId)))
                .replace(`{${"private_group_id"}}`, encodeURIComponent(String(privateGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The filtering here is the same as /api/buyer/{buyer_org_id}/contact except that it limits the resultsto those within the Private group
         * @summary Get a list of Contacts in the Private group
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {string} [myContacts] 
         * @param {Array<'-created' | '-firstname' | '-lastname' | 'created' | 'firstname' | 'lastname'>} [ordering] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateContactList: async (buyerOrgId: string, privateGroupId: string, myContacts?: string, ordering?: Array<'-created' | '-firstname' | '-lastname' | 'created' | 'firstname' | 'lastname'>, page?: number, pageSize?: number, search?: string, status?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPrivateContactList', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'privateGroupId' is not null or undefined
            assertParamExists('orgPrivateContactList', 'privateGroupId', privateGroupId)
            const localVarPath = `/v2/org/{buyer_org_id}/private/{private_group_id}/contact`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"private_group_id"}}`, encodeURIComponent(String(privateGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (myContacts !== undefined) {
                localVarQueryParameter['my_contacts'] = myContacts;
            }

            if (ordering) {
                localVarQueryParameter['ordering'] = ordering.join(COLLECTION_FORMATS.csv);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of the found Contact.  The user details will not be populated if the contact status is not APPROVED
         * @summary Get the details of a single Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {string} privateGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateContactRetrieve: async (buyerOrgId: string, contactId: string, privateGroupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgPrivateContactRetrieve', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('orgPrivateContactRetrieve', 'contactId', contactId)
            // verify required parameter 'privateGroupId' is not null or undefined
            assertParamExists('orgPrivateContactRetrieve', 'privateGroupId', privateGroupId)
            const localVarPath = `/v2/org/{buyer_org_id}/private/{private_group_id}/contact/{contact_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contactId)))
                .replace(`{${"private_group_id"}}`, encodeURIComponent(String(privateGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationPrivateGroupContactApi - functional programming interface
 * @export
 */
export const OrganisationPrivateGroupContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationPrivateGroupContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds the Contact to the Private group
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {PrivateGroupAddContact} privateGroupAddContact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPrivateContactAddCreate(buyerOrgId: string, privateGroupId: string, privateGroupAddContact: PrivateGroupAddContact, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPrivateContactAddCreate(buyerOrgId, privateGroupId, privateGroupAddContact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Once removed from the Private group, the contact will still exist under the organisation
         * @summary Removes the contact from the Private group
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {string} privateGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPrivateContactDestroy(buyerOrgId: string, contactId: string, privateGroupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPrivateContactDestroy(buyerOrgId, contactId, privateGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The filtering here is the same as /api/buyer/{buyer_org_id}/contact except that it limits the resultsto those within the Private group
         * @summary Get a list of Contacts in the Private group
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {string} [myContacts] 
         * @param {Array<'-created' | '-firstname' | '-lastname' | 'created' | 'firstname' | 'lastname'>} [ordering] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPrivateContactList(buyerOrgId: string, privateGroupId: string, myContacts?: string, ordering?: Array<'-created' | '-firstname' | '-lastname' | 'created' | 'firstname' | 'lastname'>, page?: number, pageSize?: number, search?: string, status?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedContactDetailedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPrivateContactList(buyerOrgId, privateGroupId, myContacts, ordering, page, pageSize, search, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details of the found Contact.  The user details will not be populated if the contact status is not APPROVED
         * @summary Get the details of a single Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {string} privateGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgPrivateContactRetrieve(buyerOrgId: string, contactId: string, privateGroupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgPrivateContactRetrieve(buyerOrgId, contactId, privateGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationPrivateGroupContactApi - factory interface
 * @export
 */
export const OrganisationPrivateGroupContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationPrivateGroupContactApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds the Contact to the Private group
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {PrivateGroupAddContact} privateGroupAddContact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateContactAddCreate(buyerOrgId: string, privateGroupId: string, privateGroupAddContact: PrivateGroupAddContact, options?: any): AxiosPromise<ContactDetailed> {
            return localVarFp.orgPrivateContactAddCreate(buyerOrgId, privateGroupId, privateGroupAddContact, options).then((request) => request(axios, basePath));
        },
        /**
         * Once removed from the Private group, the contact will still exist under the organisation
         * @summary Removes the contact from the Private group
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {string} privateGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateContactDestroy(buyerOrgId: string, contactId: string, privateGroupId: string, options?: any): AxiosPromise<void> {
            return localVarFp.orgPrivateContactDestroy(buyerOrgId, contactId, privateGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * The filtering here is the same as /api/buyer/{buyer_org_id}/contact except that it limits the resultsto those within the Private group
         * @summary Get a list of Contacts in the Private group
         * @param {string} buyerOrgId 
         * @param {string} privateGroupId 
         * @param {string} [myContacts] 
         * @param {Array<'-created' | '-firstname' | '-lastname' | 'created' | 'firstname' | 'lastname'>} [ordering] Ordering
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [search] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateContactList(buyerOrgId: string, privateGroupId: string, myContacts?: string, ordering?: Array<'-created' | '-firstname' | '-lastname' | 'created' | 'firstname' | 'lastname'>, page?: number, pageSize?: number, search?: string, status?: string, options?: any): AxiosPromise<PaginatedContactDetailedList> {
            return localVarFp.orgPrivateContactList(buyerOrgId, privateGroupId, myContacts, ordering, page, pageSize, search, status, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of the found Contact.  The user details will not be populated if the contact status is not APPROVED
         * @summary Get the details of a single Contact
         * @param {string} buyerOrgId 
         * @param {string} contactId 
         * @param {string} privateGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgPrivateContactRetrieve(buyerOrgId: string, contactId: string, privateGroupId: string, options?: any): AxiosPromise<ContactDetailed> {
            return localVarFp.orgPrivateContactRetrieve(buyerOrgId, contactId, privateGroupId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orgPrivateContactAddCreate operation in OrganisationPrivateGroupContactApi.
 * @export
 * @interface OrganisationPrivateGroupContactApiOrgPrivateContactAddCreateRequest
 */
export interface OrganisationPrivateGroupContactApiOrgPrivateContactAddCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupContactApiOrgPrivateContactAddCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupContactApiOrgPrivateContactAddCreate
     */
    readonly privateGroupId: string

    /**
     * 
     * @type {PrivateGroupAddContact}
     * @memberof OrganisationPrivateGroupContactApiOrgPrivateContactAddCreate
     */
    readonly privateGroupAddContact: PrivateGroupAddContact
}

/**
 * Request parameters for orgPrivateContactDestroy operation in OrganisationPrivateGroupContactApi.
 * @export
 * @interface OrganisationPrivateGroupContactApiOrgPrivateContactDestroyRequest
 */
export interface OrganisationPrivateGroupContactApiOrgPrivateContactDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupContactApiOrgPrivateContactDestroy
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupContactApiOrgPrivateContactDestroy
     */
    readonly contactId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupContactApiOrgPrivateContactDestroy
     */
    readonly privateGroupId: string
}

/**
 * Request parameters for orgPrivateContactList operation in OrganisationPrivateGroupContactApi.
 * @export
 * @interface OrganisationPrivateGroupContactApiOrgPrivateContactListRequest
 */
export interface OrganisationPrivateGroupContactApiOrgPrivateContactListRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupContactApiOrgPrivateContactList
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupContactApiOrgPrivateContactList
     */
    readonly privateGroupId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupContactApiOrgPrivateContactList
     */
    readonly myContacts?: string

    /**
     * Ordering
     * @type {Array<'-created' | '-firstname' | '-lastname' | 'created' | 'firstname' | 'lastname'>}
     * @memberof OrganisationPrivateGroupContactApiOrgPrivateContactList
     */
    readonly ordering?: Array<'-created' | '-firstname' | '-lastname' | 'created' | 'firstname' | 'lastname'>

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof OrganisationPrivateGroupContactApiOrgPrivateContactList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrganisationPrivateGroupContactApiOrgPrivateContactList
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupContactApiOrgPrivateContactList
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupContactApiOrgPrivateContactList
     */
    readonly status?: string
}

/**
 * Request parameters for orgPrivateContactRetrieve operation in OrganisationPrivateGroupContactApi.
 * @export
 * @interface OrganisationPrivateGroupContactApiOrgPrivateContactRetrieveRequest
 */
export interface OrganisationPrivateGroupContactApiOrgPrivateContactRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupContactApiOrgPrivateContactRetrieve
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupContactApiOrgPrivateContactRetrieve
     */
    readonly contactId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationPrivateGroupContactApiOrgPrivateContactRetrieve
     */
    readonly privateGroupId: string
}

/**
 * OrganisationPrivateGroupContactApi - object-oriented interface
 * @export
 * @class OrganisationPrivateGroupContactApi
 * @extends {BaseAPI}
 */
export class OrganisationPrivateGroupContactApi extends BaseAPI {
    /**
     * 
     * @summary Adds the Contact to the Private group
     * @param {OrganisationPrivateGroupContactApiOrgPrivateContactAddCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPrivateGroupContactApi
     */
    public orgPrivateContactAddCreate(requestParameters: OrganisationPrivateGroupContactApiOrgPrivateContactAddCreateRequest, options?: any) {
        return OrganisationPrivateGroupContactApiFp(this.configuration).orgPrivateContactAddCreate(requestParameters.buyerOrgId, requestParameters.privateGroupId, requestParameters.privateGroupAddContact, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Once removed from the Private group, the contact will still exist under the organisation
     * @summary Removes the contact from the Private group
     * @param {OrganisationPrivateGroupContactApiOrgPrivateContactDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPrivateGroupContactApi
     */
    public orgPrivateContactDestroy(requestParameters: OrganisationPrivateGroupContactApiOrgPrivateContactDestroyRequest, options?: any) {
        return OrganisationPrivateGroupContactApiFp(this.configuration).orgPrivateContactDestroy(requestParameters.buyerOrgId, requestParameters.contactId, requestParameters.privateGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The filtering here is the same as /api/buyer/{buyer_org_id}/contact except that it limits the resultsto those within the Private group
     * @summary Get a list of Contacts in the Private group
     * @param {OrganisationPrivateGroupContactApiOrgPrivateContactListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPrivateGroupContactApi
     */
    public orgPrivateContactList(requestParameters: OrganisationPrivateGroupContactApiOrgPrivateContactListRequest, options?: any) {
        return OrganisationPrivateGroupContactApiFp(this.configuration).orgPrivateContactList(requestParameters.buyerOrgId, requestParameters.privateGroupId, requestParameters.myContacts, requestParameters.ordering, requestParameters.page, requestParameters.pageSize, requestParameters.search, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of the found Contact.  The user details will not be populated if the contact status is not APPROVED
     * @summary Get the details of a single Contact
     * @param {OrganisationPrivateGroupContactApiOrgPrivateContactRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationPrivateGroupContactApi
     */
    public orgPrivateContactRetrieve(requestParameters: OrganisationPrivateGroupContactApiOrgPrivateContactRetrieveRequest, options?: any) {
        return OrganisationPrivateGroupContactApiFp(this.configuration).orgPrivateContactRetrieve(requestParameters.buyerOrgId, requestParameters.contactId, requestParameters.privateGroupId, options).then((request) => request(this.axios, this.basePath));
    }
}
